import { roomDossierContext } from "../../cb/interfaces/context"
import { roomCleanup, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { ListenerGroup } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { getPrivateShowContext, getSpyShowContext, leavePrivateOrSpyShowAlertChain } from "../privateShow"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"

import type { IRoomContext } from "../context"
import type { IPrivateShowParameters, ISettingsUpdateNotification } from "../messageInterfaces"

export class PrivateShowInfo extends Component<HTMLDivElement> {
    private roomSettings: ISettingsUpdateNotification
    private currentStatus: RoomStatus
    private isAgeVerified: boolean
    private privateContext: IPrivateShowParameters | undefined
    private spyContext: { spyPrice: number } | undefined // spy price at time of spy start

    constructor() {
        super()

        const listenerGroup = new ListenerGroup()

        roomLoaded.listen((context: IRoomContext) => {
            const {
                allowPrivateShow,
                privatePrice,
                spyPrice,
                privateMinMinutes,
                allowShowRecordings,
                roomStatus,
                isAgeVerified,
                hasFanClub,
                activePassword,
                premiumPrivatePrice,
                premiumPrivateMinMinutes,
            } = context.dossier

            this.updateData(
                {
                    allowPrivateShow,
                    privatePrice,
                    spyPrice,
                    privateMinMinutes,
                    allowShowRecordings,
                    hasFanClub,
                    activePassword,
                    premiumPrivatePrice,
                    premiumPrivateMinMinutes,
                },
                roomStatus,
                isAgeVerified,
            )

            context.chatConnection.event.settingsUpdate.listen((notification: ISettingsUpdateNotification) => {
                // Reset private request if broadcaster switches up private show settings before accepting
                if (this.currentStatus === RoomStatus.PrivateRequesting && this.privateShowSettingsChanged(notification)) {
                    leavePrivateOrSpyShowAlertChain(context.chatConnection, false)
                }

                this.updateData(notification, this.currentStatus, this.isAgeVerified)
            }).addTo(listenerGroup)

            context.chatConnection.event.statusChange.listen(({ previousStatus, currentStatus }) => {
                if (previousStatus !== currentStatus) {
                    if (previousStatus === RoomStatus.PrivateWatching) {
                        this.privateContext = undefined
                    } else if (previousStatus === RoomStatus.PrivateSpying) {
                        this.spyContext = undefined
                    }
                }

                this.updateData(this.roomSettings, currentStatus, this.isAgeVerified)
            }).addTo(listenerGroup)
        })

        roomCleanup.listen(() => {
            listenerGroup.removeAll()
            this.privateContext = undefined
            this.spyContext = undefined
        })
    }

    protected initData(): void {
        this.roomSettings = {
            allowPrivateShow: false,
            privateMinMinutes: 0,
            privatePrice: 0,
            spyPrice: 0,
            allowShowRecordings: false,
            hasFanClub: false,
            activePassword: false,
            premiumPrivatePrice: 0,
            premiumPrivateMinMinutes: 0,
        }
        this.currentStatus = RoomStatus.Offline
        this.isAgeVerified = false
        this.privateContext = undefined
        this.spyContext = undefined
    }

    protected initUI(): void {
        const containerStyle: CSSX.Properties = {
            fontSize: "12px",
            lineHeight: "16px",
        }

        this.element = <div colorClass="PrivateShowInfo" style={containerStyle} />
        this.render()
    }

    private updateData(roomSettings: ISettingsUpdateNotification, currentStatus: RoomStatus, isAgeVerified: boolean): void {
        this.roomSettings = roomSettings
        this.currentStatus = currentStatus
        this.isAgeVerified = isAgeVerified
        this.render()
    }

    private privateShowSettingsChanged(roomSettings: ISettingsUpdateNotification): boolean {
        if (featureFlagIsActive("PremPrivShow") && (this.roomSettings.premiumPrivatePrice !== roomSettings.premiumPrivatePrice || this.roomSettings.premiumPrivateMinMinutes !== roomSettings.premiumPrivateMinMinutes)) {
            return true
        }
        return this.roomSettings.allowPrivateShow !== roomSettings.allowPrivateShow
            || this.roomSettings.allowShowRecordings !== roomSettings.allowShowRecordings
            || this.roomSettings.privateMinMinutes !== roomSettings.privateMinMinutes
            || this.roomSettings.privatePrice !== roomSettings.privatePrice
    }

    private render(): HTMLDivElement {
        const isPrivateWatching = this.currentStatus === RoomStatus.PrivateWatching
        const isSpying = this.currentStatus === RoomStatus.PrivateSpying

        if (!isPrivateWatching && !isSpying && (!this.roomSettings.allowPrivateShow || !this.isAgeVerified)) {
            this.renderElementContents(<PrivateShowUnavailable />)
            return this.element
        }

        if (this.currentStatus === RoomStatus.PrivateNotWatching) {
            this.renderElementContents(
                <SpyShow
                    spyPrice={this.roomSettings.spyPrice}
                    roomStatus={this.currentStatus}
                />,
            )
        } else if (isPrivateWatching) {
            const renderPrivateContents = (context: IPrivateShowParameters) => {
                this.renderElementContents(
                    <PrivateShow
                        privatePrice={context.privatePrice}
                        privateMinMinutes={context.privateMinMinutes}
                        allowShowRecordings={context.allowShowRecordings}
                        roomStatus={this.currentStatus}
                    />,
                )
            }

            if (this.privateContext !== undefined) {
                renderPrivateContents(this.privateContext)
            } else {
                getPrivateShowContext(roomDossierContext.getState().room)
                    .then((context) => {
                        this.privateContext = context
                        renderPrivateContents(this.privateContext)
                    })
                    .catch(() => {renderPrivateContents(this.roomSettings)})
            }
        } else if (isSpying) {
            const renderSpyContext = (context: { spyPrice: number }) => {
                this.renderElementContents(
                    <SpyShow
                        spyPrice={context.spyPrice}
                        roomStatus={this.currentStatus}
                    />,
                )
            }

            if (this.spyContext !== undefined) {
                renderSpyContext(this.spyContext)
            } else {
                getSpyShowContext(roomDossierContext.getState().room)
                    .then((context) => {
                        this.spyContext = context
                        renderSpyContext(this.spyContext)
                    })
                    .catch(() => {renderSpyContext(this.roomSettings)})
            }
        } else {
            this.renderElementContents(
                <PrivateShow
                    privatePrice={this.roomSettings.privatePrice}
                    privateMinMinutes={this.roomSettings.privateMinMinutes}
                    allowShowRecordings={this.roomSettings.allowShowRecordings}
                    roomStatus={this.currentStatus}
                />,
            )
        }

        return this.element
    }

    private renderElementContents(element: HTMLElement): void {
        this.removeAllDOMChildren()
        this.element.appendChild(element)
        this.didRepositionEvent.fire(undefined)
    }
}

const Title = (props: { text: string, styles?: CSSX.Properties }): HTMLSpanElement => {
    const style: CSSX.Properties = {
        lineHeight: "24px",
        display: "block",
        ...props.styles,
    }

    return <span colorClass="title" style={style}>{props.text}</span>
}

const ConnectionIndicator = (props: { roomStatus: RoomStatus }): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        display: "flex",
        alignItems: "center",
    }
    const indicatorStyle: CSSX.Properties = {
        marginRight: "8px",
    }

    const isPrivateRequesting = props.roomStatus === RoomStatus.PrivateRequesting
    const isPrivateWatching = props.roomStatus === RoomStatus.PrivateWatching
    const isPrivateSpying = props.roomStatus === RoomStatus.PrivateSpying

    if (isPrivateRequesting) {
        return (
            <div style={containerStyle}>
                <img src={`${STATIC_URL_MOBILE}waiting-indicator.svg`} style={indicatorStyle} />
                <span colorClass="waitingText">{`${i18n.waitingToConnect}...`}</span>
            </div>
        )
    } else if (isPrivateWatching || isPrivateSpying) {
        return (
            <div style={containerStyle}>
                <img src={`${STATIC_URL_MOBILE}connected-indicator.svg`} style={indicatorStyle} />
                <span colorClass="connectedText">
                    {isPrivateWatching ? i18n.started : i18n.spyingNow}
                </span>
            </div>
        )
    }
    return <div style={{ display: "none" }} />
}

const Header = (props: { titleText: string, roomStatus: RoomStatus }): HTMLDivElement => {
    const headerStyle: CSSX.Properties = {
        display: "flex",
        alignItems: "center",
        marginBottom: "4px",
    }

    return (
        <div style={headerStyle}>
            <Title text={props.titleText} styles={{ marginRight: "8px" }}/>
            <ConnectionIndicator roomStatus={props.roomStatus} />
        </div>
    )
}

const PrivateShow = (props: {
    privatePrice: number,
    privateMinMinutes: number,
    allowShowRecordings: boolean,
    roomStatus: RoomStatus,
}): HTMLDivElement => {
    return (
        <div style={{ marginRight: "4px" }}>
            <Header titleText={i18n.privateShowText} roomStatus={props.roomStatus} />
            <div>
                <span>
                    {`${i18n.priceTokensPerMinuteMessage(props.privatePrice, true)}, `}
                </span>
                <span>
                    {`${i18n.privateShowMinimumMinuteMessage(props.privateMinMinutes, true)}.`}
                </span>
            </div>
            {props.allowShowRecordings
                ?
                    <span>
                        {i18n.youWillReceiveRecording}
                    </span>
                :
                    <span>
                        {i18n.broadcasterDisabledRecordings}
                    </span>
            }
        </div>
    )
}

const PrivateShowUnavailable = (): HTMLDivElement => {
    return (
        <div>
            <Title text={i18n.privateShowText} />
            <span>{i18n.privatesNotAvailable}</span>
        </div>
    )
}

const SpyShow = (props: { spyPrice: number, roomStatus: RoomStatus }): HTMLDivElement => {
    return (
        <div>
            <Header titleText={i18n.privateShowSpyLabel} roomStatus={props.roomStatus} />
            {props.spyPrice > 0 &&
                <span>
                    {`${i18n.spyShowCost}: ${i18n.priceTokensPerMinuteMessage(props.spyPrice, true)}`}
                </span>
            }
            {props.spyPrice <= 0 &&
                <span>{i18n.privateShowSpyDisabled}</span>
            }
        </div>
    )
}
