import {
    isHomepageFiltersActive, isRoomRoomlistSpaActive,
    PageType,
    shouldShowHomepageFilters,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass, colorClass } from "../../colorClasses"
import { addGenderToSubjectLinks } from "../../roomList"
import { cacheHomepageFilters } from "./filters/homepageFiltersUtil"
import { spaNavigation } from "./spaHelpers"

interface RoomCardSubjectProps {
    subject: string
}

export class RoomCardSubject extends HTMLComponent<HTMLUListElement, RoomCardSubjectProps> {
    protected initUI(): void {
        for (const el of this.element.querySelectorAll("a")) {
            addColorClass(el, colorClass.camSubjectTagColor)
            addEventListenerPoly("click", el, (event: MouseEvent) => {
                if (!isFilterInPathActive()) {
                    spaNavigation.fire(event)
                    if (isHomepageFiltersActive()) {
                        cacheHomepageFilters()
                    }
                } else if (UrlState.current.state.pageType === PageType.HOME ||
                    (isRoomRoomlistSpaActive() && UrlState.current.state.pageType === PageType.ROOM)) {
                    event.preventDefault()
                    UrlState.current.navigateTo(el.href)
                }
            })
            if (shouldShowHomepageFilters()) {
                // Apply existing filters only if there's homepage filters
                el.href = UrlState.current.getURLFromPartialURL(el.href)
            }
        }
        if (!shouldShowHomepageFilters()) {
            // Since getURLFromPartialURL already handles appending gender paths on homepage filter paths
            addGenderToSubjectLinks(this.element)
        }
    }

    protected createElement(props: RoomCardSubjectProps): HTMLUListElement {
        const subject = <ul className={`subject ${colorClass.camSubjectColor}`}>
            <li dangerouslySetInnerHTML={{ __html: props.subject }}></li>
        </ul>
        subject.title = subject.textContent
        return subject
    }
}
