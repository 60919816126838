import { isFilterInPathActive as isFilterInPathActivePrefetch } from "@multimediallc/cb-roomlist-prefetch"
import { getCookieOrUndefined } from "@multimediallc/web-utils/storage"
import { featureFlagIsActive } from "./featureFlag"

export const isTagFilterPaginationActive = (): boolean => featureFlagIsActive("HmpgTagsPag")

export const isPgEmoticonsAutocompleteActive = (): boolean => featureFlagIsActive("PGEmoticonsAC")

export const isLanguageSubdomainActive = (): boolean => {
    const continuityCookie = getCookieOrUndefined("language_subdomain_continuity")
    return featureFlagIsActive("LanguageSubdomain") || continuityCookie !== undefined
}

export const isPSRequestPMTabRedirectActive = (): boolean => featureFlagIsActive("PSRequestPMTabRedirect")

export const isFilterInPathActive = (): boolean => isFilterInPathActivePrefetch()

export const isScrollDownNoticeActive = (): boolean => featureFlagIsActive("ScrollDownNotice")

export const isPrivateSpyBadgesActive = (): boolean => featureFlagIsActive("PvtSpyBdgs")

export const isBlockMetaDataActive = (): boolean => featureFlagIsActive("BlckMtUpdt")

export const isRecommendedFollowRoomsActive = (): boolean => featureFlagIsActive("RcmFlwRm")

export const isReactUserListActive = (): boolean => featureFlagIsActive("ReactUserList2")
