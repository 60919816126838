import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import {
    getTokenBalance,
    sendTip,
    tipMessageMargin,
    tipsInPast24HoursUpdate,
    tokenBalanceUpdate,
} from "../../cb/api/tipping"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { pageContext } from "../../cb/interfaces/context"
import { cleanTipAmountInput, createTipAmountInput, isValidTipInput, popUpPurchasePage, popUpTokenPurchaseModal, purchaseTokensUrl, recordTipTypeViewed } from "../../cb/ui/tipping"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { normalizeResource } from "../api"
import { isNotLoggedIn } from "../auth"
import { roomLoaded } from "../context"
import { createDivotBottom, createDivotLeft, createDivotTop } from "../divot"
import { getCoords } from "../DOMutils"
import { EventRouter } from "../events"
import { getViewportHeight, getViewportWidth } from "../mobilelib/viewportDimension"
import { addPageAction } from "../newrelic"
import { OverlayComponent } from "../overlayComponent"
import { RoomStatus } from "../roomStatus"
import { RoomType } from "../roomUtil"
import { i18n } from "../translation"
import { videoModeHandler } from "../videoModeHandler"
import { SendTipButton } from "./sendTipButton"
import type { ITipRequest } from "../specialoutgoingmessages"

export interface ITipSent {
    readonly amount?: number
    readonly success?: boolean
}

export class TipCallout extends OverlayComponent {
    private hasLowSatisfactionScore: boolean
    private isAgeVerified: boolean
    private roomName: string
    private roomType = RoomType.Public
    private tokenBalanceSpan: HTMLSpanElement
    private tokenBalance = 0
    private isTokenAmountAlreadyTooHigh = false
    private sendTipButton: SendTipButton
    private sendTipForm: HTMLFormElement
    private tipAmountInput: HTMLInputElement
    private tipMessageLabel: HTMLDivElement
    private tipMessageDiv: HTMLDivElement
    private tipMessageTextarea: HTMLTextAreaElement
    private tipOptionsSelect: HTMLSelectElement | undefined
    private invalidTipAmountDiv: HTMLDivElement
    private lowScoreWarning: HTMLDivElement
    private ctrlSNotice: HTMLDivElement
    private isHighTipAmountWarningActive = false
    private bottomDivot: HTMLDivElement
    private topDivot: HTMLDivElement
    private leftDivot: HTMLDivElement
    private divotOffsetLeft = 96
    tipSent = new EventRouter<ITipSent>("tipSent")
    closed = new EventRouter<undefined>("tipClosed")
    isVisible = false

    constructor(private tipSource: string, private topSectionWrapper: HTMLDivElement,
                private sendTipButtonSpan: HTMLSpanElement) {
        super()

        // region DOM Creation
        this.element.id = "SplitModeTipCallout" // for color css
        this.element.dataset.testid = "send-tip-callout"
        addColorClass(this.element, colorClass.defaultColor)
        this.element.style.display = "none"
        this.element.style.position = "absolute"
        this.element.style.width = "390px"
        this.element.style.height = "auto"
        this.element.style.top = "0"
        this.element.style.borderWidth = "2px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderRadius = "4px"
        this.element.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        this.element.style.fontSize = "12px"
        this.element.style.overflow = "visible"

        const handleShiftTab = () => {
            if (this.sendTipButton.hasFocus() && !this.sendTipButton.focusPrev()) {
                this.tipMessageTextarea.focus()
            } else if (document.activeElement === this.tipMessageTextarea) {
                if (this.tipOptionsSelect !== undefined) {
                    this.tipOptionsSelect.focus()
                } else {
                    this.tipAmountInput.focus()
                }
            } else if (document.activeElement === this.tipOptionsSelect) {
                this.tipAmountInput.focus()
            } else if (!this.sendTipButton.hasFocus()) {
                this.sendTipButton.focusPrev()
            }
        }
        const handleTab = () => {
            if (this.sendTipButton.hasFocus() && !this.sendTipButton.focusNext()) {
                this.tipAmountInput.focus()
            } else if (document.activeElement === this.tipAmountInput) {
                if (this.tipOptionsSelect !== undefined) {
                    this.tipOptionsSelect.focus()
                } else {
                    this.tipMessageTextarea.focus()
                }
            } else if (document.activeElement === this.tipOptionsSelect) {
                this.tipMessageTextarea.focus()
            } else if (!this.sendTipButton.hasFocus()) {
                this.sendTipButton.focusNext()
            }
        }
        const handleArrowKeys = (next: boolean) => {
            if (this.sendTipButton.hasFocus()) {
                if (next) {
                    this.sendTipButton.focusNextMenuItem()
                } else {
                    this.sendTipButton.focusPrevMenuItem()
                }
            }
        }

        addEventListenerPoly("keydown", this.element, (event: KeyboardEvent) => {
            if (event.code === "Escape") {
                this.hide()
            } else if (event.code === "Tab") {
                if (event.shiftKey) {
                    handleShiftTab()
                } else {
                    handleTab()
                }
                event.preventDefault()
            } else if (event.code === "ArrowDown") {
                event.preventDefault()
                handleArrowKeys(true)
            } else if (event.code === "ArrowUp") {
                event.preventDefault()
                handleArrowKeys(false)
            }
        })

        this.bottomDivot = createDivotBottom("", "", "74px")
        addColorClass(this.bottomDivot, "bottomDivot")
        this.element.appendChild(this.bottomDivot)

        this.topDivot = createDivotTop("", "", "74px")
        addColorClass(this.topDivot, "topDivot")
        this.element.appendChild(this.topDivot)

        this.leftDivot = createDivotLeft("", "", "30px")
        addColorClass(this.leftDivot, "leftDivot")
        this.element.appendChild(this.leftDivot)

        this.overlay.style.bottom = ""

        const titleBar = document.createElement("div")
        addColorClass(titleBar, "titleBar")
        titleBar.innerText = i18n.sendTipText
        titleBar.style.fontSize = "15px"
        titleBar.style.fontFamily = "UbuntuBold, Helvetica, Arial, sans-serif"
        titleBar.style.padding = "6px"
        this.element.appendChild(titleBar)

        const tokenBalanceDiv = document.createElement("div")
        tokenBalanceDiv.style.display = "inline-block"
        tokenBalanceDiv.style.width = "100%"
        const balanceLabel = document.createElement("span")
        balanceLabel.innerText = i18n.currentBalanceText
        balanceLabel.style.fontWeight = "bold"
        balanceLabel.style.display = "inline-block"
        balanceLabel.style.padding = "6px"
        tokenBalanceDiv.appendChild(balanceLabel)
        this.tokenBalanceSpan = document.createElement("span")
        this.tokenBalanceSpan.dataset.testid = "token-balance"
        addColorClass(this.tokenBalanceSpan, "tokenBalance")
        this.tokenBalanceSpan.style.display = "inline-block"
        this.tokenBalanceSpan.style.fontWeight = "bold"
        this.tokenBalanceSpan.style.padding = "6px 6px 6px 0"
        tokenBalanceDiv.appendChild(this.tokenBalanceSpan)
        const purchaseTokensLink = document.createElement("a")
        addColorClass(purchaseTokensLink, "purchaseTokens")
        purchaseTokensLink.innerText = i18n.purchaseTokensText
        purchaseTokensLink.href = normalizeResource(`${purchaseTokensUrl}?source=${pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"]}`)
        purchaseTokensLink.style.display = "inline-block"
        purchaseTokensLink.style.fontSize = "11px"
        purchaseTokensLink.style.marginLeft = "12px"
        purchaseTokensLink.style.cssFloat = "right"
        purchaseTokensLink.style.padding = "6px"
        purchaseTokensLink.dataset.testid = "purchase-tokens"
        purchaseTokensLink.onclick = () => {
            popUpPurchasePage({ source: pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"], target: purchaseTokensLink })
            return false
        }

        tokenBalanceDiv.appendChild(purchaseTokensLink)
        this.element.appendChild(tokenBalanceDiv)

        this.lowScoreWarning = document.createElement("div")
        addColorClass(this.lowScoreWarning, "warning")
        this.lowScoreWarning.style.display = "none"
        this.lowScoreWarning.style.fontWeight = "bold"
        this.lowScoreWarning.style.padding = "6px"
        this.lowScoreWarning.style.textAlign = "center"
        this.lowScoreWarning.style.position = "absolute"
        this.lowScoreWarning.style.left = "50%"
        const lowScoreWarningTop = document.createElement("div")
        lowScoreWarningTop.innerText = i18n.satisfactionWarningText
        this.lowScoreWarning.appendChild(lowScoreWarningTop)
        const lowScoreWarningBottom = document.createElement("div")
        lowScoreWarningBottom.innerText = i18n.tipWarningText
        this.lowScoreWarning.appendChild(lowScoreWarningBottom)
        this.element.appendChild(this.lowScoreWarning)

        this.sendTipForm = document.createElement("form")

        const tipAmountDiv = document.createElement("label")
        const tipAmountLabel = document.createElement("span")
        tipAmountLabel.innerText = i18n.tipAmountText
        tipAmountLabel.style.display = "inline-block"
        tipAmountLabel.style.padding = "6px"
        tipAmountDiv.appendChild(tipAmountLabel)
        this.tipAmountInput = createTipAmountInput()
        this.tipAmountInput.style.width = "5em"
        this.tipAmountInput.style.display = "inline-block"
        this.tipAmountInput.style.padding = "4px"
        this.tipAmountInput.style.borderWidth = "1px"
        this.tipAmountInput.style.borderStyle = "solid"
        this.tipAmountInput.style.borderRadius = "4px"
        this.tipAmountInput.onclick = () => {
            this.focusTipAmount()
        }
        addEventListenerPoly("input", this.tipAmountInput, () => {
            this.tipAmountChange()
        })

        tipAmountDiv.appendChild(this.tipAmountInput)
        this.sendTipForm.appendChild(tipAmountDiv)

        this.tipMessageLabel = document.createElement("div")
        this.tipMessageLabel.innerText = i18n.defaultTipMessageLabel
        this.tipMessageLabel.style.padding = "6px 6px 0 6px"
        this.sendTipForm.appendChild(this.tipMessageLabel)

        this.tipMessageDiv = document.createElement("div")
        this.tipMessageTextarea = document.createElement("textarea")
        this.tipMessageTextarea.dataset.testid = "tip-message-textarea"
        addColorClass(this.tipMessageTextarea, "tipMessageInput")
        this.tipMessageTextarea.maxLength = 255
        this.tipMessageTextarea.style.width = "100%"
        this.tipMessageTextarea.style.height = "40px"
        this.tipMessageTextarea.style.resize = "none"
        this.tipMessageTextarea.style.margin = `${tipMessageMargin}px`
        this.tipMessageTextarea.style.padding = "4px"
        this.tipMessageTextarea.style.borderWidth = "1px"
        this.tipMessageTextarea.style.borderStyle = "solid"
        this.tipMessageTextarea.style.borderRadius = "4px"
        this.tipMessageTextarea.style.boxSizing = "border-box"
        this.tipMessageDiv.appendChild(this.tipMessageTextarea)
        this.sendTipForm.appendChild(this.tipMessageDiv)

        this.tipMessageLabel.onclick = () => {
            this.tipMessageTextarea.select()
        }

        this.invalidTipAmountDiv = document.createElement("div")
        addColorClass(this.invalidTipAmountDiv, "warning")
        this.invalidTipAmountDiv.innerText = i18n.tipAmountInvalid
        this.invalidTipAmountDiv.style.display = "none"
        this.invalidTipAmountDiv.style.paddingLeft = "5px"
        this.invalidTipAmountDiv.dataset.testid = "invalid-tip"
        tipAmountDiv.appendChild(this.invalidTipAmountDiv)

        const sendTipButtonDiv = document.createElement("div")
        sendTipButtonDiv.style.textAlign = "right"
        sendTipButtonDiv.style.position = "relative"
        sendTipButtonDiv.style.display = "flex"
        sendTipButtonDiv.style.flexWrap = "wrap-reverse"
        this.ctrlSNotice = document.createElement("div")
        this.ctrlSNotice.innerText = i18n.toggleWindowMessage
        this.ctrlSNotice.style.position = "relative"
        this.ctrlSNotice.style.display = "none"
        this.ctrlSNotice.style.fontSize = "10px"
        this.ctrlSNotice.style.lineHeight = "1.3em"
        this.ctrlSNotice.style.padding = "10px"
        this.ctrlSNotice.style.textAlign = "left"
        this.ctrlSNotice.style.top = "6px"
        this.ctrlSNotice.style.left = "0px"
        sendTipButtonDiv.appendChild(this.ctrlSNotice)

        this.sendTipButton = new SendTipButton()
        this.addChild(this.sendTipButton, sendTipButtonDiv)

        this.sendTipForm.appendChild(sendTipButtonDiv)

        this.element.appendChild(this.sendTipForm)
        // endregion

        roomLoaded.listen((context) => {
            this.hasLowSatisfactionScore = context.dossier.hasLowSatisfactionScore
            this.isAgeVerified = context.dossier.isAgeVerified
            this.roomName = context.chatConnection.room()
            context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                this.roomType = roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching ? RoomType.Private : RoomType.Public
            })
        })

        tokenBalanceUpdate.listen((tokenBalanceUpdateNotification) => {
            this.updateTokenBalance(tokenBalanceUpdateNotification.tokens)
        })

        this.repositionChildren()
        addEventListenerPoly("submit", this.sendTipForm, (event: Event) => {
            event.preventDefault()

            if (!isValidTipInput(this.tipAmountInput.value)) {
                modalAlert(i18n.tipAmountInvalid)
                return
            }

            if (!this.isVisible || !this.sendTipButton.isEnabled()) {
                return
            }

            const amount = parseInt(this.tipAmountInput.value)
            if (amount > this.tokenBalance) {
                popUpTokenPurchaseModal(i18n.notEnoughTokensMessage, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
                return
            }

            addPageAction("SendTipClicked", { "amount": amount, "location": "PMTab" })
            if (amount > 100 && !this.isHighTipAmountWarningActive) {
                this.sendTipButton.promptUser(i18n.tipConfirmationMessage(amount))
                this.isHighTipAmountWarningActive = true
                this.repositionChildren()
                return
            }
            this.tipAmountInput.blur()
            let message = this.tipMessageTextarea.value
            if (this.tipOptionsSelect !== undefined) {
                message = this.tipMessageTextarea.value === ""
                    ? this.tipOptionsSelect.value
                    : `${this.tipOptionsSelect.value} | ${this.tipMessageTextarea.value}`
            }
            sendTip({
                roomName: this.roomName,
                tipAmount: this.tipAmountInput.value,
                message: message,
                source: this.tipSource,
                tipRoomType: this.roomType,
                tipType: this.sendTipButton.getTipType(),
                videoMode: videoModeHandler.getVideoMode(),
            }).then((sendTipResponse) => {
                if (sendTipResponse.success) {
                    addPageAction("SendTipSuccess", { "amount": amount, "location": "PMTab" })
                } else {
                    if (sendTipResponse.error !== undefined) {
                        if (sendTipResponse.showPurchaseLink === true) {
                            popUpTokenPurchaseModal(`${sendTipResponse.error}`, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
                        } else {
                            modalAlert(sendTipResponse.error)
                        }
                    } else {
                        error("unknown send tip error")
                    }
                }
                this.removeHighTipAmountWarning()
                this.tipMessageTextarea.value = ""
                if (sendTipResponse.tipsInPast24Hours !== undefined) {
                    tipsInPast24HoursUpdate.fire({ tokens: sendTipResponse.tipsInPast24Hours, roomName: this.roomName })
                }
                this.tipSent.fire({ amount: amount, success: sendTipResponse.success })
            }).catch((status) => {
                error(`Error sending tip. status: ${status}`)
                modalAlert(i18n.errorSendingTip)
                this.tipSent.fire({ amount: amount, success: false })
            })
            this.hide()
        })

        this.overlayClick.listen(() => {
            this.hide()
        })

        this.closed.listen(() => {
            this.sendTipButton.hideMenu()
        })
    }

    public showElement(): void {
        super.showElement()
        this.isVisible = true
    }

    protected repositionChildren(): void {
        this.tipMessageDiv.style.width = `${Math.max(0, this.element.clientWidth - (tipMessageMargin * 2))}px`

        /**
         *      This portion of code repositions the popup depending if there is space in the viewport.
         *      The idea is to keep the popup off the video as much as possible.
         *
         *      Algorithm:
         *            Place below tip button if possible, else
         *            Place above tip button (overlaps video, but no other choice)
         */

        const tipButtonRect = getCoords(this.sendTipButtonSpan)

        this.bottomDivot.style.display = "none"
        this.topDivot.style.display = "none"
        this.leftDivot.style.display = "none"
        if (this.topSectionWrapper.style.position === "fixed") {
            this.leftDivot.style.display = "block"
            this.element.style.top = `${tipButtonRect.top - 30}px`
            this.element.style.left = `${tipButtonRect.right + 12}px`
        } else {
            const left = tipButtonRect.left - 70
            // if the window width is too small for tip modal to fit we move it more to the left
            let overflowDiff = Math.max(0, left + this.element.offsetWidth - getViewportWidth() + 4)
            if (left - overflowDiff < 0) {
                // if it goes off the screen from the left we make sure the left is zero
                overflowDiff = overflowDiff + (left - overflowDiff)
            }
            this.element.style.left = `${left - overflowDiff}px`
            const showOnTop = isMobileDevice() ||
                (this.element.offsetHeight + tipButtonRect.bottom + 20 > getViewportHeight() + document.documentElement.scrollTop)
            if (showOnTop) {
                this.bottomDivot.style.display = "block"
                this.bottomDivot.style.left = `${this.divotOffsetLeft + overflowDiff}px`
                this.element.style.top = `${tipButtonRect.top - this.element.offsetHeight - 11}px`
            } else {
                this.topDivot.style.display = "block"
                this.topDivot.style.left = `${this.divotOffsetLeft + overflowDiff}px`
                this.element.style.top = `${tipButtonRect.bottom + 14}px`
            }
        }
        this.overlay.style.height = `${document.documentElement.offsetHeight}px`
    }

    private tipAmountChange(): void {
        cleanTipAmountInput(this.tipAmountInput)
        if (!isValidTipInput(this.tipAmountInput.value)) {
            this.sendTipButton.disable()
            this.invalidTipAmountDiv.style.display = "inline-block"
        } else {
            this.sendTipButton.enable()
            this.invalidTipAmountDiv.style.display = "none"
            if (this.tokenBalance > 0 && parseInt(this.tipAmountInput.value) > this.tokenBalance) {
                if (!this.isTokenAmountAlreadyTooHigh) {
                    addPageAction("TokenAmountTooHigh")
                    this.isTokenAmountAlreadyTooHigh = true
                }
            } else {
                this.isTokenAmountAlreadyTooHigh = false
            }
        }
        if (this.isHighTipAmountWarningActive) {
            this.removeHighTipAmountWarning()
        }
    }

    private removeHighTipAmountWarning(): void {
        this.sendTipButton.cancelPrompt()
        this.isHighTipAmountWarningActive = false
    }

    private tippingAllowed(): boolean {
        if (!this.isAgeVerified) {
            modalAlert("This broadcaster doesn't accept tips.")
            return false
        }
        if (isNotLoggedIn(`You must be logged in to tip. Click "OK" to login.`)) {
            return false
        }
        if (pageContext.current.isNoninteractiveUser){
            modalAlert(i18n.internalStaffTip)
            return false
        }
        return true
    }
    show(tipRequest: ITipRequest): void {
        if (!this.tippingAllowed()) {
            return
        }
        if (tipRequest.amount !== undefined) {
            this.tipAmountInput.value = tipRequest.amount.toString()
            this.tipAmountChange()
        }
        if (tipRequest.message !== undefined) {
            this.tipMessageTextarea.value = tipRequest.message
        } else {
            this.tipMessageTextarea.value = ""
        }
        if (this.hasLowSatisfactionScore) {
            this.lowScoreWarning.style.display = "block"
        } else {
            this.lowScoreWarning.style.display = "none"
        }
        if (tipRequest.usedCtrlS !== undefined && tipRequest.usedCtrlS) {
            this.ctrlSNotice.style.display = "none"
        } else {
            this.ctrlSNotice.style.display = "block"
        }

        recordTipTypeViewed(this.tipSource, this.sendTipButton)
        this.showElement()
        this.showOverlay()
        this.repositionChildren()
        this.focusTipAmount()
        this.tokenBalanceSpan.innerText = `${i18n.loadingTextLower}...`

        getTokenBalance(this.roomName).then((currentTokensResponse) => {
            this.tipAmountInput.max = currentTokensResponse.tokenBalance.toString()

            if (currentTokensResponse.tipOptions !== undefined) {
                this.tipMessageLabel.innerText = currentTokensResponse.tipOptions.label
                if (this.tipOptionsSelect !== undefined) {
                    this.tipMessageDiv.removeChild(this.tipOptionsSelect)
                }
                this.tipOptionsSelect = document.createElement("select")
                addColorClass(this.tipOptionsSelect, "tipOptionsSelect")
                this.tipOptionsSelect.style.width = "100%"
                this.tipOptionsSelect.style.fontSize = ".8125em"
                this.tipOptionsSelect.style.margin = `${tipMessageMargin}px`
                this.tipOptionsSelect.style.borderWidth = "1px"
                this.tipOptionsSelect.style.borderStyle = "solid"
                this.tipOptionsSelect.style.boxSizing = "border-box"
                this.tipMessageDiv.insertBefore(this.tipOptionsSelect, this.tipMessageTextarea)
                let option = document.createElement("option")
                option.innerText = `-- ${i18n.selectOneLabel} --`
                option.value = ""
                this.tipOptionsSelect.appendChild(option)
                for (const tipOption of currentTokensResponse.tipOptions.options) {
                    option = document.createElement("option")
                    option.innerText = tipOption.label
                    option.value = tipOption.label
                    this.tipOptionsSelect.appendChild(option)
                }
            } else if (this.tipOptionsSelect !== undefined) {
                this.tipMessageLabel.innerText = i18n.defaultTipMessageLabel
                this.tipMessageTextarea.value = ""
                this.tipMessageDiv.removeChild(this.tipOptionsSelect)
                this.tipOptionsSelect = undefined
            }
        }).catch((status) => {
            error(`Error getting token balance. status: ${status}`)
            this.tokenBalanceSpan.innerText = i18n.unknownText
        })
    }

    hide(): void {
        this.element.style.display = "none"
        this.tipAmountInput.blur()
        this.hideOverlay()
        this.isVisible = false
        this.closed.fire(undefined)
    }

    public setTipButtonSpan(button: HTMLSpanElement): void {
        this.sendTipButtonSpan = button
    }

    private focusTipAmount(): void {
        if (document.activeElement !== this.tipAmountInput) {
            this.tipAmountInput.focus()
            // select() doesn't work on older ipads (5th gen at least)
            this.tipAmountInput.setSelectionRange(0, 9999)
        }
    }

    private updateTokenBalance(tokenBalance: number): void {
        this.tokenBalance = tokenBalance
        this.tokenBalanceSpan.innerText = `${tokenBalance} ${i18n.tokenOrTokensText(tokenBalance, false)}`
    }
}
