import { trackCustomEvent } from "@convivainc/conviva-js-appanalytics"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { ReactWrapper } from "../cb/components/ReactWrapper"
import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { popUpTokenPurchaseModal } from "../cb/ui/tipping"
import { modalAlert, modalConfirm } from "./alerts"
import { getCb, normalizeResource, postCb } from "./api"
import { isNotLoggedIn } from "./auth"
import { type IChatConnection } from "./context"
import { EventRouter } from "./events"
import { featureFlagIsActive } from "./featureFlag"
import { modalBlockChatFocus } from "./modalComponent"
import { addPageAction } from "./newrelic"
import { convivaEnabled } from "./player/utils"
import { ignoreCatch } from "./promiseUtils"
import { RoomStatus } from "./roomStatus"
import { isPrivateShowRequestFeatureActive } from "./theatermodelib/privateShowRequestModal"
import { privateShowRequestOverlayDismiss, privateShowSplitModeRequest } from "./theatermodelib/userActionEvents"
import { i18n } from "./translation"
import { VideoMode, videoModeHandler } from "./videoModeHandler"
import type { IPrivateShowParameters } from "./messageInterfaces"

export const privateJoinInitiated = new EventRouter<undefined>("privateJoinInitiated")

// prevents race condition where multiple modals can open
let processingPrivateOrSpyShowRequest = false

export interface IPrivateShowRequirements {
    minimumMinutes: string
    price: number
    recordingsAllowed: boolean
    allowed: boolean
    premiumMinimumMinutes: string
    premiumPrice: number | undefined
}

export interface IPrivateShowCancelAttempt {
    allowCancel: boolean
    message: string
    earlyDetails: {
        minimumMinutes: number
        tokensPerMinute: number
        secondsRemaining: number
        tokensRemaining: number
    }
}

interface IResponseCallbacks {
    onResolve?: () => void
    onError?: () => void
    onFinally?: () => void
}

export function onValidationError(responseCallbacks?: IResponseCallbacks): void {
    if (responseCallbacks?.onError) {
        responseCallbacks?.onError()
    }
    if (responseCallbacks?.onFinally) {
        responseCallbacks.onFinally()
    }
}

export function getPrivateShowRequirements(roomName: string): Promise<IPrivateShowRequirements> {
    const url = `tipping/private_show_tokens_per_minute/${roomName}/`
    return new Promise((resolve, reject) => {
        getCb(url).then((xhr) => {
            const p = new ArgJSONMap(xhr.responseText)
            resolve({
                minimumMinutes: p.getStringWithNumbers("private_show_minimum_minutes"),
                price: p.getNumber("price"),
                recordingsAllowed: p.getBoolean("recordings_allowed"),
                allowed: p.getBoolean("allowed", true, false),
                premiumMinimumMinutes: p.getStringWithNumbers("premium_minimum_minutes", false),
                premiumPrice: p.getNumberOrUndefined("premium_price"),
            })
            p.logUnusedDebugging("parseTokensPerMinute")
        }).catch(e => {
            reject(e)
        })
    })
}

export function enterPrivateShowAlertChain(
    chatConn: IChatConnection,
    showConfirmation = true,
    responseCallbacks?: IResponseCallbacks,
    delayFiringEvent = false,
): void {
    if (pageContext.current.isNoninteractiveUser) {
        modalAlert(i18n.internalStaffPrivate)
        onValidationError(responseCallbacks)
        return
    }
    privateJoinInitiated.fire(undefined)
    if (isNotLoggedIn(i18n.loginForPrivateShow)) {
        onValidationError(responseCallbacks)
        return
    }
    addPageAction("RequestPrivateShow")
    getPrivateShowRequirements(chatConn.room()).then(data => {
        if (processingPrivateOrSpyShowRequest || chatConn.status !== RoomStatus.Public) {
            return
        }
        processingPrivateOrSpyShowRequest = true

        if (!data.allowed) {
            modalAlert(i18n.featureNotEnabled)
            processingPrivateOrSpyShowRequest = false
            return
        }

        let premiumChosen = false

        const makeRequest = () => {
            if (convivaEnabled()) {
                trackCustomEvent({
                    name: "PrivateShowRequest",
                    data: {
                        "roomName": chatConn.room(),
                    },
                })
            }
            let actualPrice = data.price
            let actualMinimumMinutes = data.minimumMinutes
            if (featureFlagIsActive("PremPrivShow") && premiumChosen && data.premiumPrice !== undefined && data.premiumMinimumMinutes !== undefined) {
                actualPrice = data.premiumPrice
                actualMinimumMinutes = data.premiumMinimumMinutes
            }
            chatConn.requestPrivateShow(actualPrice, actualMinimumMinutes, data.recordingsAllowed, premiumChosen, delayFiringEvent)
                .then(responseCallbacks?.onResolve)
                .catch((e: unknown) => {
                    if (responseCallbacks?.onError !== undefined) {
                        responseCallbacks.onError()
                    }
                    if (typeof e === "string") {
                        if (isPrivateShowRequestFeatureActive()) {
                            privateShowRequestOverlayDismiss.fire(undefined)
                        }
                        popUpTokenPurchaseModal(e, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"])
                    } else if (chatConn.status !== RoomStatus.PrivateWatching) {
                        modalAlert(i18n.errorRequestingPrivateShow)
                        error("Error entering private show", e)
                        if (isPrivateShowRequestFeatureActive()) {
                            privateShowRequestOverlayDismiss.fire(undefined)
                        }
                    }
                })
                .finally(() => {
                    if (responseCallbacks?.onFinally) {
                        responseCallbacks?.onFinally()
                    }
                    processingPrivateOrSpyShowRequest = false
                })
            addPageAction("StartPrivateShow", { "is_premium": premiumChosen })
        }

        if (
            isPrivateShowRequestFeatureActive() &&
            videoModeHandler.getVideoMode() === VideoMode.Split &&
            !pageContext.current.isMobile
        ) {
            privateShowSplitModeRequest.fire({
                requirements: data,
                makeRequest,
                stopRequest: () => {
                    processingPrivateOrSpyShowRequest = false
                },
                showConfirmation,
                chatConnection: chatConn,
            })
        } else if (featureFlagIsActive("PremPrivShow")) {
            const setPremium = (premium: boolean) => {
                premiumChosen = premium
            }
            showPrivateRequestOverlay(data, chatConn.room(), makeRequest, setPremium, () => {
                processingPrivateOrSpyShowRequest = false
            })
        } else {
            if (showConfirmation) {
                const msg = i18n.privateShowConfirmMessage(
                    chatConn.room(),
                    data.price,
                    data.minimumMinutes,
                    data.recordingsAllowed,
                )
                modalConfirm(msg, makeRequest, () => {
                    processingPrivateOrSpyShowRequest = false
                })
            } else {
                makeRequest()
            }
        }
    }).catch(ignoreCatch)
}

function showPrivateRequestOverlay(data: IPrivateShowRequirements, broadcaster: string, requestCallback: () => void, toggleCallback: (premium: boolean) => void, cancelCallback: () => void): void {
    const allowPremium = data.premiumPrice !== undefined && data.premiumPrice > 0 && data.premiumMinimumMinutes !== undefined
    if (!allowPremium) {
        // Component checks price to determine if premium is allowed
        data.premiumPrice = 0
    }
    const request = new ReactWrapper({
        component: "PremiumPrivateRequest",
        componentProps: {
            tokensPerMinute: data.price,
            minimumMinutes: data.minimumMinutes,
            premiumTokensPerMinute: data.premiumPrice,
            premiumMinimumMinutes: data.premiumMinimumMinutes,
            allowRecording: data.recordingsAllowed,
            model: broadcaster,
            toggleCallback: toggleCallback,
            requestCallback: requestCallback,
            closeCallback: () => {
                request.element.remove()
                modalBlockChatFocus.fire(false)
                cancelCallback()
            },
        },
    })
    if (videoModeHandler.getVideoMode() === VideoMode.IFS) {
        const parentElement = document.getElementById("TheaterModePlayer")
        if (parentElement !== null) {
            parentElement.appendChild(request.element)
        }
    } else {
        document.body.appendChild(request.element)
    }
    modalBlockChatFocus.fire(true)
}

function getSpyShowRequirements(roomName: string): Promise<{ price: number }> {
    const url = `tipping/spy_on_private_show_tokens_per_minute/${roomName}/`
    return new Promise((resolve, reject) => {
        getCb(url).then((xhr) => {
            const price = Number(xhr.responseText)

            if (isNaN(price)) {
                reject(`isNaN on ${price}`)
                return
            }

            resolve({ price })
        }).catch((e) => {
            reject(e)
        })
    })
}

function getFullSpyShowRequirements(roomName: string): Promise<{ standardPrice: number, fanClubPrice: number | undefined }> {
    const url = `tipping/spy_on_private_show_token_cost/${roomName}/`
    return new Promise((resolve, reject) => {
        getCb(url).then((xhr) => {
            const prices = new ArgJSONMap(xhr.responseText)
            resolve({
                standardPrice: prices.getNumber("standard_price"),
                fanClubPrice: prices.getNumberOrUndefined("fan_club_price") ?? prices.getNumber("standard_price"),
            })
        }).catch((e) => {
            reject(e)
        })
    })
}

export function enterSpyShowAlertChain(chatConn: IChatConnection, showConfirmation = true, responseCallbacks?: IResponseCallbacks): void {
    if (pageContext.current.isNoninteractiveUser) {
        modalAlert(i18n.internalStaffPrivate)
        onValidationError(responseCallbacks)
        return
    }
    privateJoinInitiated.fire(undefined)
    if (isNotLoggedIn(i18n.loginForPrivateShowSpy)) {
        onValidationError(responseCallbacks)
        return
    }
    addPageAction("RequestSpyShow")

    const showSpyModal = (standardPrice: number, fanClubPrice: number | undefined, makeRequest: () => void, closeCallback: () => void) => {
        const isFan = roomDossierContext.getState().isInFanClub
        const spyModal = new ReactWrapper({
            component: "SpyRequest",
            componentProps: {
                standardPrice: standardPrice,
                fanPrice: fanClubPrice,
                isFan: isFan,
                requestCallback: makeRequest,
                closeCallback: () => {
                    spyModal.element.remove()
                    modalBlockChatFocus.fire(false)
                    closeCallback()
                },
                joinCallback: () => {
                    window.open(normalizeResource(`/fanclub/join/${chatConn.room()}/?source=${pageContext.current?.PurchaseEventSources["SUPPORTER_SOURCE_JOIN_FAN_CLUB_BUTTON"]}`), "_blank")
                },
            },
        })
        document.body.appendChild(spyModal.element)
        modalBlockChatFocus.fire(true)
    }

    const parseSpyShowRequirements = (standardPrice: number, fanClubPrice?: number): void => {
        if (standardPrice === 0) {
            modalAlert(i18n.privateShowSpyDisabled)
            return
        }

        if (processingPrivateOrSpyShowRequest) {
            return
        }
        processingPrivateOrSpyShowRequest = true

        const makeRequest = () => {
            addPageAction("StartSpyShow")
            chatConn.requestSpyShow()
                .then(responseCallbacks?.onResolve)
                .catch((e: unknown) => {
                    if (responseCallbacks?.onError !== undefined) {
                        responseCallbacks.onError()
                    }
                    if (typeof e === "string") {
                        popUpTokenPurchaseModal(e, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"])
                    } else if (chatConn.status !== RoomStatus.PrivateSpying) {
                        modalAlert(i18n.errorRequestingSpyShow)
                        error("Error entering spy show", e)
                    }
                })
                .finally(() => {
                    if (responseCallbacks?.onFinally) {
                        responseCallbacks?.onFinally()
                    }
                    processingPrivateOrSpyShowRequest = false
                })
        }

        if (featureFlagIsActive("FanClubSpying") && fanClubPrice !== undefined) {
            showSpyModal(standardPrice, fanClubPrice, makeRequest, () => {processingPrivateOrSpyShowRequest = false})
        } else if (showConfirmation) {
            modalConfirm(i18n.privateShowSpyConfirmMessage(standardPrice), makeRequest, () => {processingPrivateOrSpyShowRequest = false})
        } else {
            makeRequest()
        }
    }
    if (featureFlagIsActive("FanClubSpying")){
        getFullSpyShowRequirements(chatConn.room()).then(({ standardPrice, fanClubPrice }) => {
            parseSpyShowRequirements(standardPrice, fanClubPrice)
        }).catch((e: unknown) => {
            if (typeof e === "string") {
                error(e)
            }
        })
    } else {
        getSpyShowRequirements(chatConn.room()).then(({ price }) => {
            parseSpyShowRequirements(price)
        }).catch((e: unknown) => {
            if (typeof e === "string") {
                error(e)
            }
        })
    }
}

export function leavePrivateOrSpyShowAlertChain(
    conn: IChatConnection,
    showConfirmation = true,
    responseCallbacks?: IResponseCallbacks,
    modalNoCallback?: () => void,
): void {
    const leaveEarly = () => {
        addPageAction("LeavePrivateShowEarly", { "is_premium": conn.premiumShowActive })
        conn.leavePrivateOrSpyShow(true)
            .then(responseCallbacks?.onResolve)
            .catch((e: IPrivateShowCancelAttempt) => {
                modalAlert(e.message)
            })
            .finally(responseCallbacks?.onFinally)
    }
    const makeRequest = () => {
        addPageAction("LeavePrivateOrSpyShow", { "is_premium": conn.premiumShowActive })
        conn.leavePrivateOrSpyShow(false)
            .then(responseCallbacks?.onResolve)
            .catch((e: IPrivateShowCancelAttempt) => {
                if (responseCallbacks?.onError !== undefined) {
                    responseCallbacks.onError()
                }
                if (e.allowCancel && e.earlyDetails !== undefined) {
                    setupPrivateCancelEarlyOverlay(e, leaveEarly)
                } else {
                    modalAlert(e.message)
                }
            })
            .finally(responseCallbacks?.onFinally)
        privateShowRequestOverlayDismiss.fire(undefined)
    }

    if (showConfirmation) {
        let confirmation = i18n.privateShowLeaveWarning
        if (featureFlagIsActive("PremPrivShow") && conn.status === RoomStatus.PrivateRequesting) {
            confirmation = i18n.privateRequestCancelConfirmation
        }
        modalConfirm(confirmation, makeRequest, modalNoCallback)
    } else {
        makeRequest()
    }
}

function setupPrivateCancelEarlyOverlay(e: IPrivateShowCancelAttempt, callback: () => void): void {
    const cancel = new ReactWrapper({
        component: "PrivateCancelEarlyOverlay",
        componentProps: {
            ...e.earlyDetails,
            leaveCallback: callback,
            closeCallback: () => {
                cancel.element.remove()
                modalBlockChatFocus.fire(false)
            },
        },
    })
    document.body.appendChild(cancel.element)
    modalBlockChatFocus.fire(true)
}

export function approvePrivateShow(): void {
    postCb("tipping/private_show_approve/", { "foo": "bar" }).then(() => {
        debug("Approved private show")
    }).catch(ignoreCatch)
}

export function declinePrivateShow(): void {
    postCb("tipping/private_show_decline/", { "foo": "bar" }).then(() => {
        debug("Declined private show")
    }).catch(ignoreCatch)
}

export function getPrivateShowContext(room: string): Promise<IPrivateShowParameters> {
    return new Promise((resolve, reject) => {
        getCb(`tipping/private_show_context/${room}/`)
            .then((xhr) => {
                const map = new ArgJSONMap(xhr.responseText)

                resolve({
                    allowPrivateShow: true,
                    privatePrice: map.getNumber("tokens_per_minute"),
                    spyPrice: map.getNumber("spy_tokens_per_minute"),
                    privateMinMinutes: map.getNumber("private_show_minimum_minutes"),
                    allowShowRecordings: map.getBoolean("allow_recording"),
                })
            })
            .catch((error: unknown) => reject(error))
    })
}

export function getSpyShowContext(room: string): Promise<{ spyPrice: number }> {
    return new Promise((resolve, reject) => {
        getCb(`tipping/spy_show_context/${room}/`)
            .then((xhr) => {
                const map = new ArgJSONMap(xhr.responseText)
                resolve({ spyPrice: map.getNumber("spy_tokens_per_minute") })
            })
            .catch((error: unknown) => reject(error))
    })
}

export function returnFromAway(): void {
    postCb("tipping/return_from_away/", { "foo": "bar" }).then(() => {
        debug("Return from away")
    }).catch(ignoreCatch)
}
